.how_it_works_banner_container{
    padding: 2em 2.5em;
}
.how_it_works_banner_container img{
    width: 94%;
    box-shadow: 1px 2px 15px 0px #00000036;
}
.how_it_works_banner_container .bannerText{
    background: #3170fd;
    color: white;
    font-size: 2.5em;
    max-width: 370px;
    padding: 0.5em 1em;
    float: right;
    bottom: 7%;
    position: absolute;
    right: -8%;
}
.hiwb_Bluebg{
    background-color: #03147d;
    height: 55vh;
    position: absolute;
    top: 20%;
    left: 1.5%;
    width: 97%;
}
.mt-5{
    margin-top: 50px;
}
.how_it_works_banner_container p{
    font-size: 1.85vw;
}
.how_it_works_banner_container .sideMenus{
    min-height: 33%;
    width: 90%;
    background: rgb(198, 234, 250);
    display: block;
    position: sticky;
    top: 0;
}

.how_it_works_banner_container div.ant-typography, .ant-typography p {
    margin-bottom: 1em;
    font-size: 1.3em;
}

.studyOverviewCol{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 23vh;
  }




@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .hiwb_Bluebg {
        height: 50vh;
        position: absolute;
        top: 10%;
        left: 1.5%;
        width: 97%;
    }
    .how_it_works_banner_container .bannerText {
        position: static;
        font-size: 1.5em;
        background:transparent;
    }
    .how_it_works_banner_container img {
        width: 100%;
    }
    .studyOverviewCol{
        height: 27vh;
    }
    .howitworksP{
        font-size: 2.8vh !important;
    }
}

@media only screen and (width:768px){
    .how_it_works_banner_container .bannerText {
        background: #3170fd;
        color: white;
        font-size: 1.7em;
        max-width: 303px;
        padding: 0.5em 1em;
        float: right;
        top: 28%;
        position: absolute;
        /* right: -8%; */
    }
    .hiwb_Bluebg {
        background-color: #03147d;
        height: 32vh;
        position: absolute;
        top: 9%;
        left: 1.5%;
        width: 97%;
    }
    .how_it_works_banner_container .bannerText {
        background: #3170fd;
        color: white;
        font-size: 1.5em;
        max-width: 277px;
        padding: 0.5em 1em;
        float: right;
        top: 29%;
        position: absolute;
        right: -8%;
    }
    .howitworksP{
        font-size: 2.8vh !important;
    }
}