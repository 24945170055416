.consentModal .ant-modal-title{
    font-size: 1em;
    max-width: 70%;
    display: block;
    margin: 0 auto;
    color: #0072ff;
}
.consentModal .ant-typography{
    font-size: 1.4em;
    text-align: justify;
    text-justify: inter-word;
}
.preExisting_Med.ant-form-item-has-success .ant-form-item-label > label{
    font-size: 12px !important;
    color: red !important;
}
