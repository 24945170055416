
@media only screen and (max-width: 768px) {
    /* For mobile phones: */

    .ant-select-dropdown.addtocalList.ant-select-dropdown-placement-bottomLeft{
    
    left: 16% !important;
}
}

@media only screen and (min-width:769px){
    .ant-select-dropdown.addtocalList.ant-select-dropdown-placement-bottomLeft {
        left: 79vw !important;
        position: relative !important;
        top: 73vh !important;
    }
}

.ant-steps-item-description {
    max-width: 250px !important;
}