span.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
    box-shadow: -1px 4px 11px #0000005c;
    background: #004b80;
    top: 75px;
    position: absolute;
}
.SideMenu li.ant-menu-item{
    padding-left: 20% !important;
    display: flex;
    justify-content: start;
    align-items: center;
}
.SideMenu li.ant-menu-item.ant-menu-item-selected{
    padding-left: 20% !important;
}
.SideMenu .ant-menu-item-selected{
background-color: #1578d62b !important; 
}

@media only screen and (min-width:769px){
    span.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left { display: none !important;}
  
}