.testLocationDrawer{
    border: 3px solid #c7c5c5;
    padding: 1em;
    border-radius: 7px;
    box-shadow: 1px 1px 8px #074f8e0c;
}

.testCalenderDrawer {
    width: 100%;
    border: 3px solid #c7c5c5;
    border-radius: 7px;
  }

.testCalenderDrawer .ant-radio-group.ant-radio-group-outline.ant-radio-group-small.ant-picker-calendar-mode-switch{
    display:none;
}