body{
    overflow-x: hidden !important;
}
.ant-layout-header {
    height: 73px !important;
}
ul.ant-menu.ant-menu-dark.centerMenus.ant-menu-root.ant-menu-horizontal {
    font-size: 17px;
    margin-top: 5px;
    height: 50px;
}
/* .ant-popover.ant-popover-placement-bottom {
    width: 22vw;
} */
li.ant-menu-item.ant-menu-item-only-child.rightNavMenuItems {
    float: right;
    background-color: transparent !important;
}
li.ant-menu-item.ant-menu-item-only-child,li.ant-menu-item.ant-menu-item-only-child.rightNavMenuItems {
    background-color: transparent !important;
}
li.ant-menu-submenu.ant-menu-submenu-horizontal{
    color:white !important;
}
li.ant-menu-item.ant-menu-item-only-child.leftNavMenus:hover span{
    border-color: #ffffff;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    height: 48px;
    display: inherit
}

li.ant-menu-item.ant-menu-item-only-child:hover button.ant-btn.ant-btn-primary span{
    border: none !important;
}

.logo {
    float: left;
    width: 5em;
    /* height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3); */
  }
  ul.ant-menu.ant-menu-dark.centerMenus.ant-menu-root.ant-menu-horizontal {
    width: 50vw;
}
.middleMenuWrapper {
    display: flex;
    justify-content: center;
}
li.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
    background-color: transparent !important;
}
li.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected span {
    border-color: #1890ff;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    height: 48px;
    display: inherit;
}