footer a.ant-typography{
    color:white;
    font-size: 17px;
    font-weight: 200;
}
footer.ant-layout-footer{
    background-color: #001529; 
    color: white !important;
    padding:3.5em 0 2em 0;
}
footer .ant-row, footer .ant-row.ant-row-center.ant-row-middle{
    max-width: 100vw;
}

@media only screen and (width:768px){
    .footerRow{
        justify-content: center !important;
      
    }
   
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    footer .ant-row.ant-row-center.ant-row-middle{
        margin: auto !important;
    }
    footer .ant-row.ant-row-center.ant-row-middle a.ant-typography{
        font-size: 12px;
    }
  }