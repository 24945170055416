.homeBanner{
    background-image:url('./images/covid19banner2.jpeg');
    height: 75vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.homeCommunityRows{
    width:100vw;
}
button.slick-arrow.slick-next{
    display:none !important;
}
.homeBanner p{
    font-size: 6vh;
    color: white;
    text-shadow: 0px 1px 5px #05101dbd;
    max-width: 40vw;
    position: absolute;
}
.bannerOverLay {
    height: inherit;
    width: inherit;
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0.3;
}
.homeCommunityImage{
    max-width: 35vw;
    font-size: 1.4em;
    box-shadow: 1px 2px 19px #00000017;
    border-radius: 2px;
}
.homeAfterBannerContentP{
    max-width: 35vw;
    font-size: 1.4em;
}
.homeTitles{
    color:#053b6f !important;
}
.whoWeAreSection{

}
.whoWeAreSubText{
    text-align: center;
    display: block;
    margin-top:-1em !important;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .ant-row.ant-row-center.ant-row-middle.homeCommunityRows{
        margin: auto !important;
    }
    .homeBanner{
        height:60vh;
    }
    .homeBanner p{
        font-size:4vh;
        max-width: 80vw;
    }
    .homeCommunityImage{
        max-width:100%;
    }
    .homeTitles{
        font-size: 2vh;
    }
    .homeAfterBannerContentP{
        max-width: 97vw;
        font-size: 1.2em;
    }
    .homeCommunityRows div.ant-col-xs-24{
        max-width:85% !important;
    }
  }
  @media only screen and (width:768px){
    h2.ant-typography, .ant-typography h2 {
        font-size: 21px;
    }
    .homeAfterBannerContentP {
        max-width: 97vw;
        font-size: 1.5vw;
    }
  }

  @media only screen and (min-width:769px){
      .rightFloatPC{
          float: right;
      }
    
  }