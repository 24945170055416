.gmap_canvas iframe{
    width: 74%;
    height: 500px;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */

    .cntRow{
 width:100vw;
    }
    .gmap_canvas iframe {
        width: 93%;
        height: 500px;
        margin: 0 auto;
        display: block;
    }
}
@media only screen and (min-width:769px){
    .cntSpace{ margin: 2em; width: 100vw; }
  
}