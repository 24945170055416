.signupContainer {
    background-image: url('./images/signupbg.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: -webkit-fill-available;
  }
  .signupContainer .textPart {
    width: 50%;
    text-align: center;
  }
  .signupContainer .textPart h2 {
    font-size: xx-large;
    color:white;
  }
  .signupContainer .textPart span {
    font-size: x-large;
    color:white;

  }
  .signupContainer .formSection {
    background: #ffffffdb;
    width: 35%;
    height: 100vh;
   
  }
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
  .signupContainer .textPart {
    width: 0;
  }
  .signupContainer .textPart h2,.signupContainer .textPart span{
    display:none;
  }
  .signupContainer .formSection{
    width: 100vw;

  }
  .tacClass .ant-col-offset-8 {
    margin-left: 4vw !important;
}
}
@media only screen and (width:768px){
.tacClass .ant-col-offset-8 {
  margin-left: 33.33333333% !important;
}
}